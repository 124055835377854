import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import "./Blog.css";
import Pagination from "../Pagination/Pagination";
import parse from "html-react-parser";
import { IoIosSearch } from "react-icons/io";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import Nodata from "../../assets/images/no-data.jpg";

const Blog = () => {
  const [Loading, setLoading] = useState(false);
  const [blogData, setBlogData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const itemsPerPage = 10;
  const [searchBlog, setSearchBlog] = useState("");
  const [lodeMore, setLodeMore] = useState("Load More");
  const [PopularDataLoading, setPopularDataLoading] = useState(false);
  const [categories, setCategories] = useState();
  const [popularBlogData, setPopularBlogData] = useState();

  useEffect(() => {
    const apiData = {
      // companyid: "F99F49B2-66A1-4ACC-9FA6-AE621CCB2B6F",
      companyid: "9B1F21C0-D3EC-46E2-9554-58148EB1BFDF",
      // companyid: "5159D3B3-1E23-4C1B-AFA6-FBC8F1D0F641",
    };

    setPopularDataLoading(true);
    const getPopularAPI = async (apiData) => {
      const data = await axios.get(
        `https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/blog/getAllPopularBlog?search=&page_no=1&rows_per_page=10&company_id=${apiData.companyid}`
      );

      return data;
    };

    getPopularAPI(apiData)
      .then((res) => {
        setCategories(res.data.responseObj.category);
        setPopularBlogData(res.data.responseObj.result);
        setPopularDataLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching blogs", error);
        toast.error("Something went wrong ");
        setPopularDataLoading(false);
      });
  }, [currentPage]);

  useEffect(() => {
    const apiData = {
      itemPerPage: itemsPerPage,
      currentPage: currentPage,
      // companyid: "F99F49B2-66A1-4ACC-9FA6-AE621CCB2B6F",
      companyid: "9B1F21C0-D3EC-46E2-9554-58148EB1BFDF",
      // companyid: "5159D3B3-1E23-4C1B-AFA6-FBC8F1D0F641",
      searchBlog: searchBlog,
    };

    const GetBlogApi = async (apiData) => {
      setLoading(true);
      const { data } = await axios.get(
        `https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/blog/get_all_blog?search=&page_no=${apiData.currentPage}&rows_per_page=${apiData.itemPerPage}&company_id=${apiData.companyid}`
      );

      return data;
    };

    GetBlogApi(apiData)
      .then((res) => {
        setBlogData(res.responseObj.result);
        // console.log(res.responseObj.result);
        setCount(res.responseObj.count || res.responseObj.result.length);
        setTimeout(() => {
          setLoading(false);
        }, 100);
      })
      .catch((error) => {
        console.log("Error fetching blogs", error);
        toast.error("Something went wrong ");
        setLoading(false);
      });
  }, []);

  const LoadMoreFunction = () => {
    setLodeMore("Loading...");

    const apiData = {
      itemPerPage: itemsPerPage,
      currentPage: currentPage + 1,
      // companyid: "F99F49B2-66A1-4ACC-9FA6-AE621CCB2B6F",
      companyid: "9B1F21C0-D3EC-46E2-9554-58148EB1BFDF",
      // companyid: "5159D3B3-1E23-4C1B-AFA6-FBC8F1D0F641",
      searchBlog: searchBlog,
    };

    const GetMoreBlogApi = async (apiData) => {
      const { data } = await axios.get(
        `https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/blog/get_all_blog?search=&page_no=${apiData.currentPage}&rows_per_page=${apiData.itemPerPage}&company_id=${apiData.companyid}`
      );

      return data;
    };

    if (blogData.length == count) {
      return;
    }

    GetMoreBlogApi(apiData)
      .then((res) => {
        setBlogData((prev) => [...prev, ...res.responseObj.result]);
        // setCount(res.responseObj.count);
        setCurrentPage((prev) => prev + 1);
        setLodeMore("Load More");
      })
      .catch((error) => {
        console.log("Error fetching blogs", error);
        setLodeMore("Load More");
        toast.error("Something went wrong ");
      });
  };

  const totalPages = Math.ceil(count / itemsPerPage);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  }

  const navigate = useNavigate();
  const handleCardClick = (data) => {
    navigate(`/blogDetails/${data}`);
  };

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCategoryClick = (value) => {
    navigate(`/blog/${value}`);
  };
  const handleSearch = (value) => {
    navigate(`/blog/search/${value}`);
  };

  return (
    <div>
      <div>
        {/*--------middle-----start-------*/}
        {/*counter--start*/}
        <div className="counter-main">
          <div className="about-men-bg blog-us-main">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="about-men"
                    data-aos="flip-left"
                    data-aos-duration={500}
                  >
                    <h2>BLOG</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about-bottom-men">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="about-bottom">
                    <h6>
                      <label>
                        <Link to={"/"} onClick={handleLinkClick}>
                          Home{" "}
                        </Link>
                      </label>
                      / Blog
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* counter---end*/}
        {/*about-us--start */}

        {Loading ? (
          <div className="h-25 mt-5 mb-5" style={{ textAlign: "center" }}>
            <h2>Loading...</h2>
          </div>
        ) : count ? (
          <div className="about-us-main">
            <div className="container">
              <div className="row">
                <div className="blogHeading ">
                  <div>
                    <h1>Blog</h1>
                  </div>
                  <form
                    className="blogSearchForm"
                    onSubmit={() => {
                      handleSearch(searchBlog);
                    }}
                  >
                    <div className="input-group">
                      <input
                        type="search"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon1"
                        className="form-control border-0 bg-light"
                        value={searchBlog}
                        onChange={(e) => setSearchBlog(e.target.value)}
                      />
                      <div className="input-group-append bg-light">
                        <button
                          id="button-addon1"
                          type="submit"
                          className="btn btn-link text-primary "
                          // onClick={()=>{handleSearch(searchBlog)}}
                        >
                          <IoIosSearch />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <>
                  {blogData?.map((item, index) =>
                    index === 0 ? (
                      <>
                        <div
                          className="col-md-12 col-lg-8 mb-4"
                          data-aos="zoom-in"
                          data-aos-duration={1000}
                          key={index}
                          // style={{ border: "1px solid black" }}
                        >
                          <div className="blog-men-text">
                            <img
                              className="img-fluid blogImg-a"
                              src={`${item.blog_image}`}
                              alt="IT Solutions Blog Image"
                            />
                            <div className="row blog-text">
                              {/* <div className="col-lg-6">
                                  <div className="blog-name">
                                    <h6>
                                      <div className="d-flex gap-2">
                                        {item?.category
                                          ?.split(",")
                                          .map((categoryItem) => {
                                            return (
                                              <div
                                                className="blogCardCategory"
                                                key={categoryItem}
                                                onClick={() => {
                                                  handleCategoryClick(
                                                    categoryItem
                                                  );
                                                  handleLinkClick();
                                                }}
                                              >
                                                {categoryItem}
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </h6>
                                  </div>
                                </div>
                                <div className="col-lg-6  ">
                                  <div className="blog-name float-end">
                                    <h6>
                                      <i className="fa-solid fa-calendar-days" />
                                      &nbsp;{formatDate(item.created_on)}
                                    </h6>
                                  </div>
                                </div> */}
                              <div className="categoryWrap-b">
                                <h6>
                                  <div className="d-flex gap-2 flex-wrap">
                                    {item?.category
                                      ?.split(",")
                                      .map((categoryItem) => {
                                        return (
                                          <div
                                            className="blogCardCategory"
                                            key={categoryItem}
                                            onClick={() => {
                                              handleCategoryClick(categoryItem);
                                              handleLinkClick();
                                            }}
                                          >
                                            {categoryItem}
                                          </div>
                                        );
                                      })}
                                  </div>
                                </h6>
                                <h6 className="BlogDate-b">
                                  <i className="fa-solid fa-calendar-days" />
                                  &nbsp;{formatDate(item.created_on)}
                                </h6>
                              </div>
                              <div className="col-lg-12 pt-2">
                                <div className="blog-text1">
                                  <h6
                                    className="blogTitle-a"
                                    title={item.blog_title}
                                  >
                                    {item.blog_title}
                                  </h6>
                                  <p className="blogContent-a">
                                    {parse(item.content)}
                                  </p>

                                  <strong
                                    id="readMore-a"
                                    onClick={() => {
                                      handleCardClick(item.blog_id);
                                      handleLinkClick();
                                    }}
                                  >
                                    read more...
                                  </strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="col-lg-4 col-md-12 blog-right-main"
                          data-aos="zoom-in"
                          data-aos-duration={1000}
                          key={index}
                        >
                          <div className="blog-right">
                            <div className="col-lg-12">
                              <div>
                                <h4>Category</h4>
                              </div>
                              <div className="blogCategories">
                                {PopularDataLoading ? (
                                  <div style={{ textAlign: "center" }}>
                                    <h6>Loading...</h6>
                                  </div>
                                ) : (
                                  <>
                                    {categories?.length ? (
                                      categories.map((item, index) => {
                                        return (
                                          <span
                                            key={index} // Added key for each element in the list
                                            className="blogCategory"
                                            onClick={() => {
                                              handleCategoryClick(item);
                                              handleLinkClick();
                                            }}
                                          >
                                            {item}
                                          </span>
                                        );
                                      })
                                    ) : (
                                      <div className="emptyCategory">
                                        No Category Present
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                              <hr />
                              <div>
                                <h4 className="m-0">Popular articles</h4>
                              </div>

                              {PopularDataLoading ? (
                                <div style={{ textAlign: "center" }}>
                                  <h6>Loading...</h6>
                                </div>
                              ) : (
                                <>
                                  <div className="PopularBlogWrap">
                                    {popularBlogData.length ? (
                                      <>
                                        {popularBlogData.map((item, index) => {
                                          return (
                                            <div
                                              className="card bg-light-subtle mt-2 PopularBlogCard"
                                              onClick={() => {
                                                handleCardClick(item.blog_id);
                                                handleLinkClick();
                                              }}
                                            >
                                              <img
                                                src={item.blog_image}
                                                className="card-img-top"
                                                alt="Blog Image"
                                              />
                                              <div className="card-body">
                                                <div className="text-section">
                                                  <p
                                                    title={item.blog_title}
                                                    className="card-text"
                                                  >
                                                    {item.blog_title}
                                                  </p>
                                                  <span id="categorySpan">
                                                    {item.category
                                                      .split(",")[0]
                                                      .trim()}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      <div className="EmptyPopularData">
                                        No Popular Article Present
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}

                              <hr />

                              <div>
                                <h4> Follow us</h4>
                              </div>

                              <div>
                                <div className=" blogSocialLinks">
                                  <a
                                    href="https://www.facebook.com/satellitekeyit"
                                    target="_blank"
                                    className="facebook"
                                  >
                                    <FaFacebook />
                                  </a>
                                  <a
                                    href="https://www.youtube.com/@SatelliteKeyIT"
                                    target="_blank"
                                    className="youtube"
                                  >
                                    <FaYoutube />
                                  </a>
                                  <a
                                    href="https://www.instagram.com/satellitekey?igsh=aG53bDg5MjJ4ZDA1"
                                    target="_blank"
                                    className="instagram"
                                  >
                                    <FaInstagram />
                                  </a>
                                  <a
                                    href="https://www.linkedin.com/company/satellitekeyit/"
                                    target="_blank"
                                    className="linkedin"
                                  >
                                    <FaLinkedin />
                                  </a>
                                  <a
                                    href="https://x.com/SatelliteKeyIT?t=xb05qvykeRZZG2THNPCqYg&s=09"
                                    target="_blank"
                                    className="twitter"
                                  >
                                    <FaXTwitter />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div
                        className="col-md-6 col-lg-4 mb-4"
                        data-aos="zoom-in"
                        data-aos-duration={1000}
                        key={index}
                        // style={{ border: "1px solid black" }}
                      >
                        <div className="blog-men-text blogMain">
                          <img
                            className="img-fluid blogImg-a"
                            src={`${item.blog_image}`}
                            alt="IT Solutions Blog Image"
                          />
                          <div className="row blog-text">
                            {/* <div className="col-lg-6">
                                <div className="blog-name">
                                  <h6>
                                    <div className="blogCardCategory">
                                      {item.category.split(",")[0].trim()}
                                    </div>
                                  </h6>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="blog-name float-end">
                                  <h6>
                                    <i className="fa-solid fa-calendar-days" />
                                    &nbsp;{formatDate(item.created_on)}
                                  </h6>
                                </div>
                              </div> */}

                            <div className="categoryWrap-b">
                              <h6>
                                <div className="blogCardCategory" onClick={()=>{handleCategoryClick(item.category.split(",")[0].trim()); handleLinkClick();}}>
                                  {item.category.split(",")[0].trim()}
                                </div>
                              </h6>
                              <h6 className="BlogDate-b">
                                <i className="fa-solid fa-calendar-days" />
                                &nbsp;{formatDate(item.created_on)}
                              </h6>
                            </div>
                            <div className="col-lg-12 pt-2">
                              <div className="blog-text1">
                                <h6
                                  className="blogTitle-a"
                                  title={item.blog_title}
                                >
                                  {item.blog_title}
                                </h6>
                                <p className="blogContent-a">
                                  {parse(item.content)}
                                </p>

                                <strong
                                  id="readMore-a"
                                  onClick={() => {
                                    handleCardClick(item.blog_id);
                                    handleLinkClick();
                                  }}
                                >
                                  read more...
                                </strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}

                  {count === blogData.length ? (
                    ""
                  ) : (
                    <button
                      disabled={count === blogData.length}
                      style={{
                        cursor:
                          count === blogData.length ? "not-allowed" : "pointer",
                      }}
                      className="LoadMoreBlog w-100"
                      onClick={LoadMoreFunction}
                    >
                      {lodeMore}
                    </button>
                  )}
                </>
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-5 mb-5 d-flex w-100 flex-column justify-content-center align-items-center">
            <div>
              <img src={Nodata} />
            </div>
            <h6>No Blog Present</h6>
          </div>
        )}
      </div>
    </div>
  );
};

export default Blog;
