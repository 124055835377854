import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import "./BlogSearch.css";
import Pagination from "../Pagination/Pagination";
import parse from "html-react-parser";
import { IoIosSearch } from "react-icons/io";
import noData from "../../assets/images/no-data.jpg";

const Blog = () => {
  const [Loading, setLoading] = useState(false);
  const [blogData, setBlogData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const itemsPerPage = 9;
  const [searchBlog, setSearchBlog] = useState("");

  let { value } = useParams();

  useEffect(() => {
    const apiData = {
      itemPerPage: itemsPerPage,
      currentPage: currentPage,
      companyid: "9B1F21C0-D3EC-46E2-9554-58148EB1BFDF",
      Category: value,
    };

    const GetBlogApi = async (apiData) => {
      setLoading(true);
      const { data } = await axios.get(
        `https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/blog/get_all_blog?search=${value}&page_no=${apiData.currentPage}&rows_per_page=${apiData.itemPerPage}&company_id=${apiData.companyid}`
      );

      return data;
    };

    GetBlogApi(apiData)
      .then((res) => {
        // console.log(res, "sres")
        // console.log(res.responseObj.result);
        setBlogData(res.responseObj.result);
        setCount(res.responseObj.count);
        setTimeout(() => {
          setLoading(false);
        }, 100);
      })
      .catch((error) => {
        console.log("Error fetching blogs", error);
        toast.error("Something went wrong ");
        setLoading(false);
      });
  }, [currentPage, value]);

  const navigate = useNavigate();
  const handleCardClick = (data) => {
    navigate(`/blogdetails/${data}`);
  };
  const handleSearch = (value) => {
    navigate(`/blog/search/${value}`);
  };

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const totalPages = Math.ceil(count / itemsPerPage);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  }

  return (
    <div>
      <div>
        {/*--------middle-----start-------*/}
        {/*counter--start*/}
        <div className="counter-main">
          <div className="about-men-bg blog-us-main">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="about-men"
                    data-aos="flip-left"
                    data-aos-duration={500}
                  >
                    <h2>BLOG</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about-bottom-men">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="about-bottom">
                    <h6>
                      <label>
                        <Link to={"/"} onClick={handleLinkClick}>
                          Home{" "}
                        </Link>
                      </label>
                      / Blog
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* counter---end*/}
        {/*about-us--start */}
        <div className="about-us-main">
          <div className="container">
            <div className="row">
              {Loading ? (
                <div style={{ textAlign: "center" }}>
                  <h2>Loading...</h2>
                </div>
              ) : (
                <>
                  <div className="blogHeadingSearch col-12 col-sm-12 col-lg-12 w-100 mb-4">
                    <div>
                      <h5>Search results for: &lt;&lt; {value} &gt;&gt;</h5>
                    </div>
                    <form
                      className="blogSearchForm w-100"
                      onSubmit={() => {
                        handleSearch(searchBlog);
                      }}
                    >
                      <div className="input-groupSearch ">
                        <input
                          type="search"
                          placeholder="What're you searching for?"
                          aria-describedby="button-addon1"
                          className="form-control border-0 bg-light"
                          value={searchBlog}
                          onChange={(e) => setSearchBlog(e.target.value)}
                        />
                        <div className="input-group-append bg-light">
                          <button
                            id="button-addon1"
                            type="submit"
                            className="btn btn-link text-primary "
                          >
                            <IoIosSearch />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>

                  {blogData.length ? (
                    blogData?.map((item, index) => {
                      return (
                        <div
                          className="col-md-6 col-lg-4 mb-4 "
                          data-aos="zoom-in"
                          data-aos-duration={1000}
                          key={index}
                        >
                          <div className="blog-men-text blogMain">
                            <img
                              className="img-fluid blogImg-a"
                              src={`${item.blog_image}`}
                              alt="IT Solutions Blog Image"
                            />
                            <div className="row blog-text">
                              <div className="col-lg-6">
                                <div className="blog-name">
                                  <h6>
                                    <div className="blogCardCategory">
                                      {item.category.split(",")[0].trim()}
                                    </div>
                                  </h6>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="blog-name float-end">
                                  <h6>
                                    <i className="fa-solid fa-calendar-days" />
                                    &nbsp;{formatDate(item.created_on)}
                                  </h6>
                                </div>
                              </div>
                              <div className="col-lg-12 pt-2">
                                <div className="blog-text1">
                                  <h6 className="blogTitle-a">
                                    {item.blog_title}
                                  </h6>
                                  <p className="blogContent-a">
                                    {parse(item.content)}
                                  </p>

                                  <strong
                                    id="readMore-a"
                                    onClick={() => {
                                      handleCardClick(item.blog_id);
                                      handleLinkClick();
                                    }}
                                  >
                                    read more...
                                  </strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="mt-5 d-flex w-100 flex-column justify-content-center align-items-center">
                      <div>
                        <img src={noData} />
                      </div>
                      <h6>No Blog Found</h6>
                    </div>
                  )}
                </>
              )}
            </div>
            {totalPages > 1 ? (
              <div className="pagination-a" onClick={handleLinkClick}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={setCurrentPage}
                  handleLinkClick={handleLinkClick}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/*about-us-end*/}

        {/*----middle-----end--------------------*/}
      </div>
    </div>
  );
};

export default Blog;
