import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import PureCounter from "@srexi/purecounterjs";
import banner1Img from "../../assets/images/banner 1.png";
import banner2Img from "../../assets/images/banner 2.png";
import banner3Img from "../../assets/images/banner 3.png";
import banner4Img from "../../assets/images/banner 4.png";
import banner5Img from "../../assets/images/banner 5.png";
import banner6Img from "../../assets/images/banner 6.png";
import aboutImg from "../../assets/images/about.png";
import headingLeft from "../../assets/images/heading_left.png";
import headingRight from "../../assets/images/heading_right.png";
import webDevelopment1Img from "../../assets/images/web devlopment 1.png";
import webDevelopment2Img from "../../assets/images/app devlopment 2.png";
import webDevelopment3Img from "../../assets/images/it devlopment 3.png";
import webDevelopment4Img from "../../assets/images/soft devlopment 4.png";
import webDevelopment5Img from "../../assets/images/cloud devlopment 5.png";
import webDevelopment6Img from "../../assets/images/digital devlopment 6.png";
import htmlCssImg from "../../assets/images/html css.png";
import bootstrapImg from "../../assets/images/bootstrap.png";
import angularImg from "../../assets/images/angular.png";
import reactImg from "../../assets/images/react.png";
import vuejsImg from "../../assets/images/vuejs.png";
import nextjsImg from "../../assets/images/nextjs.png";
import phpImg from "../../assets/images/php.png";
import nodejsImg from "../../assets/images/nodejs.png";
import netImg from "../../assets/images/net.png";
import pythonImg from "../../assets/images/python.png";
import mySQLimg from "../../assets/images/mysql.png";
import mongoDbImg from "../../assets/images/mondodb.png";
import sqlLiteImg from "../../assets/images/SQL.png";
import postgreSqlImg from "../../assets/images/PostgreSQL.png";
import googleCloudImg from "../../assets/images/Google Cloud.png";
import amazonImg from "../../assets/images/Amazon Web Services.png";
import MSAzureImg from "../../assets/images/Microsoft Azure.png";
import clientsIcon from "../../assets/images/clients-icon.png";
import SpotrackImg from "../../assets/images/spotrack.png";
import starImg from "../../assets/images/star.png";
import skymmdImg from "../../assets/images/skymmd.png";
import wwImg from "../../assets/images/ww.png";
import khomeImg from "../../assets/images/k-home.png";
import { useTranslation } from "react-i18next";
import SEO from "../SEO/SEO";
import washwoosh from "../../assets/images/washwoosh.png";
import hrms from "../../assets/images/hrms.png";

const Home = () => {
  const { t } = useTranslation();

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    new PureCounter();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplaySpeed: 3500,
    arrows: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      {/*--------middle-----start-------*/}
      {/*---banner----start---*/}
      <SEO
        title={"SatelliteKey IT Solutions"}
        description={
          "Discover the best IT solutions tailored to your business needs. Our expert services offer cutting-edge technology, reliable support, and innovative strategies to drive success and efficiency. Satellitekey IT Solutions"
        }
        scriptData={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          url: "https://satellitekeyit.com",
          name: "Informative Site",
          description:
            "Discover the best IT solutions tailored to your business needs. Our expert services offer cutting-edge technology, reliable support, and innovative strategies to drive success and efficiency. Satellitekey IT Solutions",
          publisher: {
            "@type": "Organization",
            name: "SatelliteKey IT Solutions",
          },
        }}
        canonical={"https://satellitekeyit.com/"}
      />
      <div
        id="carouselExampleCaptions"
        className="carousel slide"
        data-bs-ride="carousel"
        data-bs-interval="3000"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={0}
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          />
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={1}
            aria-label="Slide 2"
          />
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={2}
            aria-label="Slide 3"
          />
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={3}
            aria-label="Slide 3"
          />
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={4}
            aria-label="Slide 4"
          />
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={5}
            aria-label="Slide 5"
          />
        </div>
        <div className="carousel-inner">
          <div className="container">
            <div className="carousel-item active">
              <img
                src={banner1Img}
                className="d-block img-fluid"
                alt="IT Solutions website design development Banner Image"
              />
              <div className="carousel-caption d-none d-md-block">
                <h4>{t("H114")}</h4>
                <h1 className="pb-2">{t("H115")}</h1>
                <p className="banner-about"> {t("H1")}</p>
                <div className="banner-bt">
                  <Link to={"/contact"} onClick={handleLinkClick}>
                    {t("H2")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={banner2Img}
                className="d-block img-fluid"
                alt="IT Solutions Application Development Support Banner Image"
              />
              <div className="carousel-caption d-none d-md-block">
                <h4>{t("H3")}</h4>
                <h1 className="pb-2">
                  {t("H4")} <br />
                  {t("H5")}
                </h1>
                <p className="banner-about"> {t("H6")}</p>
                <div className="banner-bt">
                  <Link to={"/contact"} onClick={handleLinkClick}>
                    {t("H2")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={banner3Img}
                className="d-block img-fluid"
                alt="IT Solutions Custom Software Development Banner Image"
              />
              <div className="carousel-caption d-none d-md-block">
                <h4>{t("H7")}</h4>
                <h1 className="pb-2">
                  {t("H8")} <br /> {t("H9")}
                </h1>
                <p className="banner-about">{t("H10")}</p>
                <div className="banner-bt">
                  <Link to={"/contact"} onClick={handleLinkClick}>
                    {t("H2")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={banner4Img}
                className="d-block img-fluid"
                alt="IT Solutions Cloud Computing Banner Image"
              />
              <div className="carousel-caption d-none d-md-block">
                <h4>{t("H11")}</h4>
                <h1 className="pb-2">
                  {t("H12")}
                  <br /> {t("H13")}
                </h1>
                <p className="banner-about">{t("H14")}</p>
                <div className="banner-bt">
                  <Link to={"/contact"} onClick={handleLinkClick}>
                    {t("H2")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={banner5Img}
                className="d-block img-fluid"
                alt="IT Solutions Digital Marketing Banner Image"
              />
              <div className="carousel-caption d-none d-md-block">
                <h4>{t("H15")}</h4>
                <h1 className="pb-2">
                  {t("H16")} <br /> {t("H13")}
                </h1>
                <p className="banner-about">{t("H18")}</p>
                <div className="banner-bt">
                  <Link to={"/contact"} onClick={handleLinkClick}>
                    {t("H2")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={banner6Img}
                className="d-block img-fluid"
                alt="IT Solutions IT consulting Banner Image"
              />
              <div className="carousel-caption d-none d-md-block">
                <h4>{t("H19")}</h4>
                <h1 className="pb-2">{t("H20")}</h1>
                <p className="banner-about">{t("H21")}</p>
                <div className="banner-bt">
                  <Link to={"/contact"} onClick={handleLinkClick}>
                    {t("H2")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">{t("H113")}</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">{t("H22")}</span>
        </button>
      </div>
      {/* banner-end-*/}
      {/*counter--start*/}
      {/* <div className="counter-main">
        <div className="container">
          <div id="counter">
            <div className="row">
              <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                <div
                  className="item"
                  data-aos="zoom-out"
                  data-aos-duration={1000}
                >
                  <i className="fa-regular fa-calendar-days icon-color" />
                  <h2 className="count">
                    <span
                      data-purecounter-start="0"
                      data-purecounter-end="06"
                      data-purecounter-duration="3"
                      className="purecounter"
                      style={{ color: "white" }}
                    ></span>
                  </h2>
                  <h3 className="text">Years+ Experience</h3>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                <div
                  className="item"
                  data-aos="zoom-out"
                  data-aos-duration={1000}
                >
                  <i className="fa-regular fa-face-smile icon-color" />
                  <h2 className="count">
                    <span
                      data-purecounter-start="0"
                      data-purecounter-end="150"
                      data-purecounter-duration="3"
                      className="purecounter"
                      style={{ color: "white" }}
                    ></span>
                  </h2>
                  <h3 className="text">happy clients</h3>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                <div
                  className="item"
                  data-aos="zoom-out"
                  data-aos-duration={1000}
                >
                  <i className="fa-solid fa-briefcase icon-color" />
                  <h2 className="count">
                    <span
                      data-purecounter-start="0"
                      data-purecounter-end="205"
                      data-purecounter-duration="3"
                      className="purecounter"
                      style={{ color: "white" }}
                    ></span>
                  </h2>
                  <h3 className="text">Projects Delivery</h3>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                <div
                  className="item"
                  data-aos="zoom-out"
                  data-aos-duration={1000}
                >
                  <i className="fa-solid fa-people-group icon-color" />
                  <h2 className="count">
                    <span
                      data-purecounter-start="0"
                      data-purecounter-end="25"
                      data-purecounter-duration="3"
                      className="purecounter"
                      style={{ color: "white" }}
                    ></span>
                  </h2>
                  <h3 className="text">Dedicated Developers</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* counter---end*/}
      {/*about-us--start */}
      <div className="about-us-main">
        <div className="container">
          <div className="row aboutUs-a">
            <div
              className="col-12 col-md-4 col-lg-5 aboutUsImg-a"
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <img className="img-fluid" src={aboutImg} alt />
            </div>
            <div
              className="col-12 col-md-8 col-lg-7"
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <div className="about-content">
                <h4>{t("H23")}</h4>
                <h2>
                  {t("H24")} <span>{t("H25")}</span>
                </h2>
                <p className="fst-italic">
                  {t("H26")}
                  <br />
                  <br />
                  {t("H27")}
                </p>
                <div className="read-more-bt">
                  <Link to={"/about"} onClick={handleLinkClick}>
                    {t("H28")} ⇾
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*about-us-end*/}
      {/*--Services-We-Offer---start */}
      <div className="services-main">
        <div className="container">
          <div
            className="heading-main"
            data-aos="zoom-in-down"
            data-aos-duration={1000}
          >
            <h2>
              <img className="heading-image" src={headingLeft} />
              {t("H29")}
              <span>{t("H30")}</span>
              <img className="heading-image" src={headingRight} />
            </h2>
            <p>{t("H31")}</p>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <div
                className="services-box"
                data-aos="zoom-in-up"
                data-aos-duration={1000}
              >
                <img src={webDevelopment1Img} />
                <h3>{t("H32")}</h3>
                <p>{t("H33")}</p>
                <div className="services-read-more-bt">
                  <Link to={"/services"} onClick={handleLinkClick}>
                    {t("H28")} ⇾
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div
                className="services-box"
                data-aos="zoom-in-up"
                data-aos-duration={1000}
              >
                <img src={webDevelopment2Img} />
                <h3>{t("H34")}</h3>
                <p>{t("H35")}</p>
                <div className="services-read-more-bt">
                  <Link to={"/services"} onClick={handleLinkClick}>
                    {t("H28")} ⇾
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div
                className="services-box"
                data-aos="zoom-in-up"
                data-aos-duration={1000}
              >
                <img className="img-fluid" src={webDevelopment3Img} />
                <h3>{t("H36")}</h3>
                <p>{t("H37")}</p>
                <div className="services-read-more-bt">
                  <Link to={"/services"} onClick={handleLinkClick}>
                    {t("H28")} ⇾
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div
                className="services-box"
                data-aos="zoom-in-up"
                data-aos-duration={1000}
              >
                <img className="img-fluid" src={webDevelopment4Img} />
                <h3>{t("H38")}</h3>
                <p>{t("H39")}</p>
                <div className="services-read-more-bt">
                  <Link to={"/services"} onClick={handleLinkClick}>
                    {t("H28")} ⇾
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div
                className="services-box"
                data-aos="zoom-in-up"
                data-aos-duration={1000}
              >
                <img className="img-fluid" src={webDevelopment5Img} />
                <h3>{t("H40")}</h3>
                <p>{t("H41")}</p>
                <div className="services-read-more-bt">
                  <Link to={"/services"} onClick={handleLinkClick}>
                    {t("H28")} ⇾
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div
                className="services-box"
                data-aos="zoom-in-up"
                data-aos-duration={1000}
              >
                <img className="img-fluid" src={webDevelopment6Img} />
                <h3>{t("H42")}</h3>
                <p>{t("H43")}</p>
                <div className="services-read-more-bt">
                  <Link to={"/services"} onClick={handleLinkClick}>
                    {t("H28")} ⇾
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Services-We-Offer-end*/}
      {/*--Technologies We Work---start */}
      <div className="technologies-main">
        <div className="container">
          <div
            className="heading-main"
            data-aos="zoom-in-down"
            data-aos-duration={1000}
          >
            <h2>
              <img className="heading-image" src={headingLeft} />
              {t("H44")} <span>{t("H45")}</span>
              <img className="heading-image" src={headingRight} />
            </h2>
            <p>{t("H46")}</p>
          </div>
          <div className="row">
            <div className="col-sm-12 technology-section">
              <div className="technology-work">
                <ul
                  className="nav nav-tabs mb-4 justify-content-center"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item lg-me-3" role="presentation">
                    <button
                      className="nav-link active"
                      id="Frontend-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Frontend"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="false"
                    >
                      Frontend
                    </button>
                  </li>
                  <li className="nav-item lg-me-3" role="presentation">
                    <button
                      className="nav-link "
                      id="Backend-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Backend"
                      type="button"
                      role="tab"
                      aria-controls="Backend"
                      aria-selected="true"
                    >
                      Backend
                    </button>
                  </li>
                  <li className="nav-item lg-me-3" role="presentation">
                    <button
                      className="nav-link"
                      id="Database-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Database"
                      type="button"
                      role="tab"
                      aria-controls="Database"
                      aria-selected="false"
                    >
                      Database
                    </button>
                  </li>
                  <br />
                  <li className="nav-item lg-me-3" role="presentation">
                    <button
                      className="nav-link"
                      id="hosting-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#hosting"
                      type="button"
                      role="tab"
                      aria-controls="hosting"
                      aria-selected="false"
                    >
                      Hosting
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="app-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#app"
                      type="button"
                      role="tab"
                      aria-controls="application"
                      aria-selected="false"
                    >
                      Mobile Apps
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade active show"
                    id="Frontend"
                    role="tabpanel"
                    aria-labelledby="Frontend-tab"
                  >
                    <div className="row justify-content-center">
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <div
                          className="technologies-box"
                          data-aos="zoom-in"
                          data-aos-duration={1000}
                        >
                          <div className="image-box">
                            {" "}
                            <img src={htmlCssImg} />
                          </div>
                          <p>HTML &amp; CSS</p>
                        </div>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <div
                          className="technologies-box"
                          data-aos="zoom-in"
                          data-aos-duration={1000}
                        >
                          <div className="image-box">
                            {" "}
                            <img src={bootstrapImg} />
                          </div>
                          <p>Bootstrap</p>
                        </div>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <div
                          className="technologies-box"
                          data-aos="zoom-in"
                          data-aos-duration={1000}
                        >
                          <div className="image-box">
                            {" "}
                            <img src={reactImg} />
                          </div>
                          <p>React</p>
                        </div>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <div
                          className="technologies-box"
                          data-aos="zoom-in"
                          data-aos-duration={1000}
                        >
                          <div className="image-box">
                            {" "}
                            <img src={angularImg} />
                          </div>
                          <p>Angular</p>
                        </div>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <div
                          className="technologies-box"
                          data-aos="zoom-in"
                          data-aos-duration={1000}
                        >
                          <div className="image-box">
                            {" "}
                            <img src={vuejsImg} />
                          </div>
                          <p>Vue.js</p>
                        </div>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <div
                          className="technologies-box"
                          data-aos="zoom-in"
                          data-aos-duration={1000}
                        >
                          <div className="image-box">
                            {" "}
                            <img src={nextjsImg} />
                          </div>
                          <p>Next</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade "
                    id="Backend"
                    role="tabpanel"
                    aria-labelledby="Backend-tab"
                  >
                    <div className="row justify-content-center">
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <div className="technologies-box">
                          <div className="image-box">
                            {" "}
                            <img src={phpImg} alt="PHP" />
                          </div>
                          <p>PHP</p>
                        </div>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <div className="technologies-box">
                          <div className="image-box">
                            {" "}
                            <img src={nodejsImg} alt="Node.js" />
                          </div>
                          <p>Node.js</p>
                        </div>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <div className="technologies-box">
                          <div className="image-box">
                            <img src={netImg} alt=".Net" />
                          </div>
                          <p>.Net</p>
                        </div>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <div className="technologies-box">
                          <div className="image-box">
                            {" "}
                            <img src={pythonImg} alt="Python" />
                          </div>
                          <p>Python</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Database"
                    role="tabpanel"
                    aria-labelledby="Database-tab"
                  >
                    <div className="row justify-content-center">
                      <div className="col-3 col-sm-3 col-md-3 col-lg-2">
                        <div className="technologies-box">
                          <div className="image-box">
                            {" "}
                            <img src={mySQLimg} alt="MySQL" />
                          </div>
                          <p>MySQL</p>
                        </div>
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-2">
                        <div className="technologies-box">
                          <div className="image-box">
                            {" "}
                            <img src={mongoDbImg} alt="MongoDB" />
                          </div>
                          <p>MongoDB</p>
                        </div>
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-2">
                        <div className="technologies-box">
                          <div className="image-box">
                            <img src={sqlLiteImg} alt="SQLite" />
                          </div>
                          <p>MS SQL</p>
                        </div>
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-2">
                        <div className="technologies-box">
                          <div className="image-box">
                            <img src={postgreSqlImg} alt="PostgreSQL" />
                          </div>
                          <p>PostgreSQL</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="hosting"
                    role="tabpanel"
                    aria-labelledby="hosting-tab"
                  >
                    <div className="row justify-content-center">
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <div className="technologies-box">
                          <div className="image-box">
                            <img src={googleCloudImg} />
                          </div>
                          <p>Google Cloud Platform</p>
                        </div>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <div className="technologies-box">
                          <div className="image-box">
                            <img src={amazonImg} />
                          </div>
                          <p>Amazon Web Services</p>
                        </div>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <div className="technologies-box">
                          <div className="image-box">
                            {" "}
                            <img src={MSAzureImg} />
                          </div>
                          <p>Microsoft Azure</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="app"
                    role="tabpanel"
                    aria-labelledby="app-tab"
                  >
                    <div className="row justify-content-center">
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <div className="technologies-box">
                          <div className="image-box">
                            {" "}
                            <img
                              src={require("../../assets/images/Android.png")}
                              alt="Google Cloud Platform"
                            />
                          </div>
                          <p>Android</p>
                        </div>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <div className="technologies-box">
                          <div className="image-box">
                            <img
                              src={require("../../assets/images/Flutter.png")}
                            />
                          </div>
                          <p>Flutter </p>
                        </div>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <div className="technologies-box">
                          <div className="image-box">
                            {" "}
                            <img
                              src={require("../../assets/images/React Native.png")}
                            />
                          </div>
                          <p>React Native</p>
                        </div>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-2">
                        <div className="technologies-box">
                          <div className="image-box">
                            {" "}
                            <img
                              src={require("../../assets/images/Google Cloud Platform Kotlin.png")}
                              alt="Google Cloud Platform"
                            />
                          </div>
                          <p>Kotlin </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Technologies We Work-end*/}
      {/*--Why-Choose-Us---start */}
      <div className="why-choose-us-main">
        <div className="container">
          <div className="row">
            <div
              className="col-12 col-md-8 col-lg-7"
              aos-init
              aos-animate
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <div className="why-choose-box">
                <h2> {t("H73")}</h2>
                <p>{t("H74")}</p>
              </div>
              <div className="row">
                <div className="col-6 col-sm-4 col-md-4 col-lg-4">
                  <div className="why-content-box">
                    <img
                      className="img-fluid pb-3"
                      src={require("../../assets/images/why-1.png")}
                    />
                    <h5>{t("H75")}</h5>
                  </div>
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-4">
                  <div className="why-content-box">
                    <img
                      className="img-fluid pb-3"
                      src={require("../../assets/images/why-2.png")}
                    />
                    <h5>{t("H76")}</h5>
                  </div>
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-4">
                  <div className="why-content-box">
                    <img
                      className="img-fluid pb-3"
                      src={require("../../assets/images/why-3.png")}
                    />
                    <h5>{t("H77")}</h5>
                  </div>
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-4">
                  <div className="why-content-box">
                    <img
                      className="img-fluid pb-3"
                      src={require("../../assets/images/why-4.png")}
                    />
                    <h5>{t("H78")}</h5>
                  </div>
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-4">
                  <div className="why-content-box">
                    <img
                      className="img-fluid pb-3"
                      src={require("../../assets/images/why-5.png")}
                    />
                    <h5>{t("H79")}</h5>
                  </div>
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-4">
                  <div className="why-content-box">
                    <img
                      className="img-fluid pb-3"
                      src={require("../../assets/images/why-6.png")}
                    />
                    <h5>{t("H80")}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-12 col-md-4 col-lg-5 girlImg-a"
              aos-init
              aos-animate
              data-aos="zoom-in"
              data-aos-duration={1000}
            >
              <div className="why-choose-box idea-image">
                <img
                  className="img-fluid float-right"
                  src={require("../../assets/images/why-choose-us1.png")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Why-Choose-Us-end*/}
      {/*--working-process-----start---*/}
      <div className="working-process-main">
        <div className="container-fluid">
          <div
            className="heading-main"
            data-aos="zoom-in-down"
            data-aos-duration={1000}
          >
            <h2>
              <img className="heading-image" src={headingLeft} /> {t("H81")}{" "}
              <span>{t("H82")}</span>{" "}
              <img className="heading-image" src={headingRight} />
            </h2>
            <p>{t("H83")}</p>
          </div>
          <div id="timeline">
            <div className="FadeIn">
              <div className="timeline-item one">
                <div className="timeline-content">
                  <h3>{t("H84")}</h3>
                  <p>{t("H85")}</p>
                </div>
              </div>
              <div className="timeline-item two">
                <div className="timeline-content">
                  <h3>{t("H32")}</h3>
                  <p>{t("H86")}</p>
                </div>
              </div>
              <div className="timeline-item three">
                <div className="timeline-content">
                  <h3>{t("H87")}</h3>
                  <p>{t("H88")}</p>
                </div>
              </div>
              <div className="timeline-item four">
                <div className="timeline-content">
                  <h3>{t("H89")}</h3>
                  <p>{t("H90")}</p>
                </div>
              </div>
              <div className="timeline-item five">
                <div className="timeline-content">
                  <h3>{t("H91")}</h3>
                  <p>{t("H92")}</p>
                </div>
              </div>
              <div className="timeline-item six">
                <div className="timeline-content right">
                  <h3>{t("H93")}</h3>
                  <p>{t("H94")}</p>
                </div>
              </div>
              <div className="timeline-item seven aaa">
                <div className="timeline-content bbb">
                  <h3>
                    {t("H95")} &amp; {t("H96")}
                  </h3>
                  <p>{t("H97")}</p>
                </div>
              </div>
              {/* <div className="timeline-item eight">
    <div className="timeline-content right">
      <h3>...........</h3>
      <p>
        SatelliteKey IT Solutions pvt.ltd. offers a comprehensive range of IT services to meet the needs of businesses of all sizes. Our services include:
      </p>
    </div>
  </div>

  <div className="timeline-item nine">
    <div className="timeline-content">
      <h3>...........</h3>
      <p>
        SatelliteKey IT Solutions pvt.ltd. offers a comprehensive range of IT services to meet the needs of businesses of all sizes. Our services include:
      </p>
    </div>
  </div> */}
            </div>
          </div>
        </div>
      </div>
      {/*working-process---end---*/}
      {/*--have-a-project-----start---*/}
      <div className="have-a-project-main">
        <div className="container">
          <div
            className="have-a-project-cuntent"
            data-aos="zoom-in"
            data-aos-duration={1000}
          >
            <h2>{t("H98")}</h2>
            <p className="py2">{t("H99")}</p>
            <div className="Let-Schedule-bt">
              <Link to={"/contact"} on onClick={handleLinkClick}>
                {t("H100")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/*have-a-project---end---*/}
      {/*--client-review-----start---*/}
      <div className="client-review-main">
        <div className="container">
          <div
            className="heading-main"
            data-aos="zoom-in-down"
            data-aos-duration={1000}
          >
            <h2>
              <img className="heading-image" src={headingLeft} />
              {t("H101")} <span> {t("H102")}</span>{" "}
              <img className="heading-image" src={headingRight} />
            </h2>
            <p>{t("H103")}</p>
          </div>
          <Slider {...settings} className="row slider-a">
            <div className="col-md-12">
              <div
                className="details-box"
                aos-init
                aos-animate
                data-aos="zoom-in"
                data-aos-duration={1000}
              >
                <img className="img-fluid client-icon ic-a" src={clientsIcon} />
                <p>{t("H104")}</p>
                <div className="a2z">
                  <div>
                    <img
                      className="img-fluid client-icon spotrack"
                      src={SpotrackImg}
                    />
                  </div>
                  <div>
                    <img className="img-fluid client-icon" src={starImg} />
                  </div>
                </div>
                <h5>Spotrack</h5>
                {/* <p>(CEO)</p> */}
              </div>
            </div>
            <div className="col-md-12">
              <div
                className="details-box"
                aos-init
                aos-animate
                data-aos="zoom-in"
                data-aos-duration={1000}
              >
                <img className="img-fluid client-icon ic-a" src={clientsIcon} />
                <p>{t("H106")}</p>
                <div className="a2z">
                  <div>
                    <img
                      className="img-fluid client-icon skymmd"
                      src={skymmdImg}
                    />
                  </div>
                  <div>
                    <img className="img-fluid client-icon" src={starImg} />
                  </div>
                </div>
                <h5>Sky MMD</h5>
                {/* <p>(CEO)</p> */}
              </div>
            </div>
            <div className="col-md-12">
              <div
                className="details-box"
                aos-init
                aos-animate
                data-aos="zoom-in"
                data-aos-duration={1000}
              >
                <img className="img-fluid client-icon ic-a" src={clientsIcon} />
                <p>{t("H108")}</p>
                <div className="a2z">
                  <div>
                    <img className="img-fluid client-icon suugam" src={wwImg} />
                  </div>
                  <div>
                    <img className="img-fluid client-icon" src={starImg} />
                  </div>
                </div>
                <h5>Suugam Techknow World Private Limited</h5>
                {/* <p>(CEO)</p> */}
              </div>
            </div>
            <div className="col-md-12">
              <div
                className="details-box"
                aos-init
                aos-animate
                data-aos="zoom-in"
                data-aos-duration={1000}
              >
                <img className="img-fluid client-icon ic-a" src={clientsIcon} />
                <p>{t("H110")}</p>
                <div className="a2z">
                  <div>
                    <img
                      className="img-fluid client-icon khome"
                      src={khomeImg}
                    />
                  </div>
                  <div>
                    <img className="img-fluid client-icon" src={starImg} />
                  </div>
                </div>
                <h5>K-Homes</h5>
                {/* <p>(CEO)</p> */}
              </div>
            </div>
            {/* <div className="col-md-12">
              <div
                className="details-box"
                aos-init
                aos-animate
                data-aos="zoom-in"
                data-aos-duration={1000}
              >
                <img
                  className="img-fluid client-icon"
                  src={client03}
                />
                <p>
                The team at SatelliteKey IT Solutions exceeded our expectations with their web development services. They understood our vision and transformed it into a website that not only looks incredible but also provides a smooth, hassle-free user experience. The site is fast, secure, and has all the features we needed to streamline our operations. Their attention to detail and commitment to quality have made a significant impact on our business. We’re thrilled with the results and look forward to working with them again in the future.
                </p>
                <div>
                  <img
                    className="img-fluid client-icon"
                    src="assets/images/client-1.png"
                  />
                </div>
                <div>
                  <img
                    className="img-fluid client-icon"
                    src={client05}
                  />
                </div>
                <h5>Rakesh Sharma</h5>
                <p>(CEO)</p>
              </div>
            </div>
            <div className="col-md-12">
              <div
                className="details-box"
                aos-init
                aos-animate
                data-aos="zoom-in"
                data-aos-duration={1000}
              >
                <img
                  className="img-fluid client-icon"
                  src={client03}
                />
                <p>
                From the moment we partnered with SatelliteKey IT Solutions, we knew we were in good hands. They took our ideas and turned them into a beautiful, functional website that truly represents our brand. The design is clean, the navigation is intuitive, and the performance is top-notch. Our customers have noticed the difference, and so have we. SatelliteKey IT Solutions has been a pleasure to work with, and we highly recommend them to anyone in need of a top-tier website
                </p>
                <div>
                  <img
                    className="img-fluid client-icon"
                    src="assets/images/client-1.png"
                  />
                </div>
                <div>
                  <img
                    className="img-fluid client-icon"
                    src={client05}
                  />
                </div>
                <h5>Rakesh Sharma</h5>
                <p>(CEO)</p>
              </div>
            </div> */}
          </Slider>
        </div>
      </div>
      {/*--client-review-----end--*/}
      {/*----middle-----end--------------------*/}

      {/* our Products */}
      <div className="services-main">
        <div className="container">
          <div
            className="heading-main"
            data-aos="zoom-in-down"
            data-aos-duration={1000}
          >
            <h2>
              <img className="heading-image" src={headingLeft} />
              Our
              <span> Products</span>
              <img className="heading-image" src={headingRight} />
            </h2>
            <p>
              SatelliteKey IT Solutions Pvt. Ltd. offers an online car cleaning
              app and a human resource management system as key products.
            </p>
          </div>
          <div className="row ProductWrap-a">
            <div className="col-12 col-md-12 col-lg-6 ">
              <div
                className="content order-last order-lg-first mb-3 sldf "
                data-aos="zoom-in-up"
                data-aos-duration={1000}
              >
                <div className="image-hover-text-container">
                  <div className="image-hover-image ">
                    <img src={washwoosh} alt="Washwoosh" className="washwooshImage" />
                  </div>
                  <div className="image-hover-text">
                    <div className="image-hover-text-bubble">
                      <span className="image-hover-text-title">Washwoosh</span>
                      Coming Soon...
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-6 ">
              <div
                className="content order-last order-lg-first mb-3 sldf"
                data-aos="zoom-in-up"
                data-aos-duration={1000}
              >
                <div className="image-hover-text-container">
                  <div className="image-hover-image">
                    <img src={hrms} alt="HRMS image" className="HrmsImage" />
                  </div>
                  <div className="image-hover-text">
                    <div className="image-hover-text-bubble">
                      <span className="image-hover-text-title">HRMS</span>
                      Coming Soon...
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
