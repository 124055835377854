import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import "./BlogDetails.css";
import parse from "html-react-parser";

const BlogDetails = () => {
  const [Loading, setLoading] = useState(false);
  const [blogDetailData, setBlogDetailData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [PopularBlogData, setPopularBlogData] = useState();
  const [PopularDataLoading, setPopularDataLoading] = useState(true);

  const { id } = useParams();
  useEffect(() => {
    const apiData = {
      id: id,
    };

    const GetBlogApi = async (apiData) => {
      const data = await axios.get(
        `https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/blog/get_blog?blog_id=${apiData.id}`
      );

      return data;
    };

    GetBlogApi(apiData)
      .then((res) => {
        setLoading(true);
        console.log(res.data.responseObj.result);
        setBlogDetailData(res.data.responseObj.result);
        setTimeout(() => {
          setLoading(false);
        }, 100);
      })
      .catch((error) => {
        console.log("Error fetching blogs", error);
        toast("Something went wrong meaning");
      });
  }, [id]);

  useEffect(() => {
    const apiData = {
      companyid: "9B1F21C0-D3EC-46E2-9554-58148EB1BFDF",
    };

    const getPopularAPI = async (apiData) => {
      const data = await axios.get(
        `https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/blog/getAllPopularBlog?search=&page_no=1&rows_per_page=10&company_id=${apiData.companyid}`
      );

      return data;
    };

    getPopularAPI(apiData).then((res) => {
      setPopularDataLoading(true);
      setCategories(res.data.responseObj.category);
      setPopularBlogData(res.data.responseObj.result);
      setPopularDataLoading(false);
    });
  }, []);

  const navigate = useNavigate();
  const handleCardClick = (data) => {
    navigate(`/blogdetails/${data}`);
  };

  const handleCategoryClick = (value) => {
    navigate(`/blog/${value}`);
  };

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  return (
    <div>
      {/*--------middle-----start-------*/}
      {/*counter--start*/}
      <div className="counter-main">
        <div className="about-men-bg blog-us-main">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="about-men"
                  data-aos="flip-left"
                  data-aos-duration={500}
                >
                  <h2>BLOG DETAILS</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-bottom-men">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="about-bottom">
                  <h6>
                    <label>
                      <Link to={"/blog"} onClick={handleLinkClick}>
                        Blog
                      </Link>
                    </label>
                    / Blog Details
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* counter---end*/}
      {/*about-us--start */}
      <div className="about-us-main">
        <div className="container">
          <div className="row">
            {Loading ? (
              <div style={{ textAlign: "center" }}>
                <h2>Loading...</h2>
              </div>
            ) : (
              <>
                <div className="col-md-8 col-lg-8 mb-4">
                  <div className="blog-men-text">
                    <img
                      className="img-fluid"
                      src={`${blogDetailData.blog_image}`}
                      alt="IT Solutions Blog Image"
                    />
                    <div className="row blog-text blog-text2">
                      <div className="col-lg-6">
                        <div className="blog-name">
                          {/* <h6>
                            <i className="fa-regular fa-user" />
                            &nbsp;By {blogDetailData?.blog_author}
                          </h6> */}
                          <div className="d-flex gap-2">
                            {blogDetailData?.category
                              ?.split(",")
                              .map((categoryItem) => {
                                return (
                                  <div
                                    className="blogCardCategory-bd"
                                    key={categoryItem}
                                    onClick={() => {
                                      handleCategoryClick(categoryItem);
                                      handleLinkClick();
                                    }}
                                  >
                                    {categoryItem}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="blog-name float-end">
                          <h6>
                            <i className="fa-solid fa-calendar-days" />
                            &nbsp;{formatDate(blogDetailData.created_on)}
                          </h6>
                        </div>
                      </div>
                      <div className="col-lg-12 pt-3">
                        <div className="blog-text1">
                          <h6>{blogDetailData.blog_title}</h6>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: blogDetailData.content,
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-lg-4 mb-4">
                  <div className="blog-men-text blog-men-text-two">
                    <div className="blog-details-right">
                      <div className="col-lg-12 p-2">
                        <div className="mt-1">
                          <h4>Popular articles</h4>
                        </div>

                        {PopularDataLoading ? (
                          <div style={{ textAlign: "center" }}>
                            <h6>Loading...</h6>
                          </div>
                        ) : (
                          <div className="PopularBlogWrap">
                            {PopularBlogData.length ? (
                              <>
                                {PopularBlogData?.map((item, index) => {
                                  return (
                                    <div
                                      className="card bg-light-subtle mt-3 PopularBlogCard"
                                      onClick={() => {
                                        handleCardClick(item.blog_id);
                                        handleLinkClick();
                                      }}
                                    >
                                      <img
                                        src={item.blog_image}
                                        className="card-img-top"
                                        alt="Blog Image"
                                      />
                                      <div className="card-body">
                                        <div className="text-section">
                                          {/* <h5 className="card-title fw-bold">
                                    {item.blog_title}
                                  </h5> */}
                                          <p
                                            title={item.blog_title}
                                            className="card-text"
                                          >
                                            {item.blog_title}
                                          </p>
                                          <span id="categorySpan">
                                            {item.category.split(",")[0].trim()}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            ) : (
                              <div className="EmptyPopularData">
                                No Popular Article Present
                              </div>
                            )}
                          </div>
                        )}

                        <hr />

                        <div className="mt-3">
                          <h4>Category</h4>
                        </div>
                        <div className="d-flex gap-2 flex-wrap">
                          {PopularDataLoading ? (
                            <div style={{ textAlign: "center" }}>
                              <h6>Loading...</h6>
                            </div>
                          ) : (
                            <>
                              {categories.length ? (
                                categories?.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="blogCategory"
                                      onClick={() => {
                                        handleCategoryClick(item);
                                        handleLinkClick();
                                      }}
                                    >
                                      {item}
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="EmptyPopularData">
                                  No Category Present{" "}
                                </div>
                              )}
                            </>
                          )}
                        </div>

                        <hr />

                        <div>
                          <h4> Follow us</h4>
                        </div>

                        <div>
                          <div className=" blogSocialLinks-bd">
                            <a
                              href="https://www.facebook.com/satellitekeyit" target="_blank"
                              className="facebook"
                            >
                              <FaFacebook />
                            </a>
                            <a
                              href="https://www.youtube.com/@SatelliteKeyIT"
                              target="_blank"
                              className="youtube"
                            >
                              <FaYoutube />
                            </a>
                            <a
                              href="https://www.instagram.com/satellitekey?igsh=aG53bDg5MjJ4ZDA1" 
                              target="_blank"
                              className="instagram"
                            >
                              <FaInstagram />
                            </a>
                            <a
                              href="https://www.linkedin.com/company/satellitekeyit/"
                              target="_blank"
                              className="linkedin"
                            >
                              <FaLinkedin />
                            </a>
                            <a
                              href="https://x.com/SatelliteKeyIT?t=xb05qvykeRZZG2THNPCqYg&s=09"
                              target="_blank"
                              className="twitter"
                            >
                              <FaXTwitter />
                            </a>
                          </div>

                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/*about-us-end*/}
      {/*----middle-----end--------------------*/}
    </div>
  );
};

export default BlogDetails;
