import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Collapse the navbar on link click (for mobile view)
    const navbarToggler = document.querySelector('.navbar-collapse');
    if (navbarToggler.classList.contains('show')) {
      navbarToggler.classList.remove('show');
    }
  };

  const location = useLocation();

  console.log(location.pathname)

  // const [acti, setActive] = useState({
  // });
  const [showTopBar, setShowTopBar] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShowTopBar(false);
      } else {
        setShowTopBar(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <nav>
      <header>
        <div className="top-bar" style={{
            display: showTopBar ? 'block' : 'none',
            transition: 'display 10s ease-in-out',
          }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <div className="top-left">
                  <ul>
                    <li>
                      <i className="fa-solid fa-phone" /> +91-9529899325
                    </li>
                    <li>
                    <a href="mailto:info@satellitekeyit.com" target="_blank" style={{color:"white", textDecoration:"none"}}>
                      <i className="fa-regular fa-envelope" />{" "}
                      info@satellitekeyit.com
                    </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <div className="top-right">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/satellitekeyit" target="_blank" >
                        <i className="fa-brands fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://x.com/SatelliteKeyIT?t=xb05qvykeRZZG2THNPCqYg&s=09"
                        target="_blank"
                      >
                        <i className="fa-brands fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/satellitekeyit/"
                        target="_blank"
                      >
                        <i className="fa-brands fa-linkedin-in" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/satellitekey?igsh=aG53bDg5MjJ4ZDA1"
                        target="_blank"
                      >
                        <i className="fa-brands fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="navbar_top" className="navbar-main">
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <Link className="navbar-brand" to={"/"} onClick={handleLinkClick}>
                <img
                  className="img-fluid"
                  src={require('../../assets/images/satellitekey-logo.png')}
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto mb-lg-0">
                  <li className="nav-item">
                    <Link
                      // className={acti?.home ? "nav-link active" : "nav-link"}
                      className={` nav-link ${
                        location.pathname === "/" ? "active" : ""
                      }`}
                      aria-current="page"
                      to={"/"}
                      onClick={() => {
                        handleLinkClick();
                        // setActive({ home: true });
                      }}
                    >
                      HOME
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      // className={acti?.about ? "nav-link active" : "nav-link"}
                      className={` nav-link ${
                        location.pathname === "/about" ? "active" : ""
                      }`}
                      to={"/about"}
                      onClick={() => {
                        handleLinkClick();
                        // setActive({ about: true });
                      }}
                    >
                      ABOUT US
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      // className={acti?.services ? "nav-link active" : "nav-link"}
                      className={` nav-link ${
                        location.pathname === "/services" ? "active" : ""
                      }`}
                      to={"/services"}
                      onClick={() => {
                        handleLinkClick();
                        // setActive({ services: true });
                      }}
                    >
                      SERVICES
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                     
                     className={` nav-link ${
                        location.pathname === "/portfolio" ? "active" : ""
                      }`}
                      to={"/portfolio"}
                      onClick={() => {
                        handleLinkClick();
                        // setActive({ portfolio: true });
                      }}
                    >
                      PORTFOLIO
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link
                      className={` nav-link ${
                        location.pathname === "/blog" ? "active" : ""
                      }`}
                      to={"/blog"}
                      onClick={() => {
                        handleLinkClick();
                        // setActive({ blog: true });
                      }}
                    >
                      BLOG
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    <Link
                      className={` nav-link ${
                        location.pathname === "/careers" ? "active" : ""
                      }`}
                      to={"/careers"}
                      onClick={() => {
                        handleLinkClick();
                      }}
                    >
                      CAREERS
                    </Link>
                  </li>
                  {/* <li className="nav-item dropdown">
			          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
			            Dropdown
			          </a>
			          <ul className="dropdown-menu">
			            <li><a className="dropdown-item" href="#">Action</a></li>
			            <li><a className="dropdown-item" href="#">Another action</a></li>
			            <li><hr className="dropdown-divider"></li>
			            <li><a className="dropdown-item" href="#">Something else here</a></li>
			          </ul>
			        </li>  */}
                </ul>
                <div className="d-flex kuchbhi" >
                  <div className="enquiry-bt">
                    <Link className="" to={"/enquiry"} onClick={handleLinkClick}>ENQUIRY NOW</Link>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </nav>
  );
};

export default Navbar;
